import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

function TinkerersLabText() {
  const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const height = "64"
  const width  = isMobile?"4000":"600"
  const font = isMobile?"64":"86"
  return (
    <>
    <svg id="svg_main_logo" height={font} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
      <path stroke="white" stroke-width="1" d="M 37.063 2.5 L 37.063 10.094 L 23.125 10.094 L 23.125 48 L 13.75 48 L 13.75 10.094 L 0 10.094 L 0 2.5 L 37.063 2.5 Z" id="0"/>
      <path stroke="white" stroke-width="1" d="M 51.344 14.188 L 51.344 48 L 42.281 48 L 42.281 14.188 L 51.344 14.188 Z M 42.024 3.758 A 4.958 4.958 0 0 0 41.75 5.437 A 5.868 5.868 0 0 0 41.758 5.755 A 4.369 4.369 0 0 0 43.141 8.813 A 4.638 4.638 0 0 0 45.227 9.94 A 6.46 6.46 0 0 0 46.812 10.125 A 6.722 6.722 0 0 0 48.088 10.009 A 4.71 4.71 0 0 0 50.484 8.812 A 4.28 4.28 0 0 0 51.609 7.099 A 4.987 4.987 0 0 0 51.875 5.437 A 5.844 5.844 0 0 0 51.871 5.233 A 4.381 4.381 0 0 0 50.5 2.094 Q 49.125 0.781 46.813 0.781 Q 45.97 0.781 45.255 0.951 A 4.523 4.523 0 0 0 43.109 2.094 A 4.305 4.305 0 0 0 42.024 3.758 Z" id="1"/>
      <path stroke="white" stroke-width="1" d="M 58.594 14.188 L 67.094 14.188 L 67.375 18.094 A 11.611 11.611 0 0 1 75.79 13.617 A 15.37 15.37 0 0 1 77.094 13.562 A 14.492 14.492 0 0 1 80.185 13.871 Q 82.101 14.289 83.522 15.276 A 7.89 7.89 0 0 1 85.094 16.719 Q 87.719 19.875 87.781 26.156 L 87.781 48 L 78.75 48 L 78.75 26.375 A 10.458 10.458 0 0 0 78.651 24.883 Q 78.398 23.135 77.5 22.203 Q 76.261 20.918 73.395 20.906 A 12.929 12.929 0 0 0 73.344 20.906 Q 69.801 20.906 67.905 23.712 A 8.208 8.208 0 0 0 67.625 24.156 L 67.625 48 L 58.594 48 L 58.594 14.188 Z" id="2"/>
      <path stroke="white" stroke-width="1" d="M 115.594 48 L 106.906 34.438 L 103.656 37.688 L 103.656 48 L 94.625 48 L 94.625 0 L 103.656 0 L 103.656 26.594 L 105.406 24.344 L 114.063 14.188 L 124.906 14.188 L 112.688 28.281 L 125.969 48 L 115.594 48 Z" id="3"/>
      <path stroke="white" stroke-width="1" d="M 126.969 31.906 L 126.969 31.031 A 22.613 22.613 0 0 1 127.524 25.923 A 18.558 18.558 0 0 1 128.937 21.922 A 14.952 14.952 0 0 1 132.276 17.402 A 14.085 14.085 0 0 1 134.516 15.734 A 15.258 15.258 0 0 1 141.312 13.617 A 18.735 18.735 0 0 1 142.75 13.563 A 17.705 17.705 0 0 1 147.447 14.152 A 12.834 12.834 0 0 1 153.672 17.937 A 14.283 14.283 0 0 1 156.751 23.519 Q 157.402 25.673 157.585 28.257 A 29.558 29.558 0 0 1 157.656 30.344 L 157.656 34.031 L 136.125 34.031 A 9.594 9.594 0 0 0 136.879 36.753 A 7.832 7.832 0 0 0 138.766 39.344 A 7.623 7.623 0 0 0 143.021 41.261 A 10.295 10.295 0 0 0 144.344 41.344 A 11.529 11.529 0 0 0 148.005 40.79 A 9.338 9.338 0 0 0 152.5 37.563 L 156.938 42.531 A 13.306 13.306 0 0 1 152.289 46.59 A 16.078 16.078 0 0 1 151.438 47.016 A 17.473 17.473 0 0 1 145.433 48.559 A 20.997 20.997 0 0 1 143.75 48.625 A 19.765 19.765 0 0 1 138.163 47.872 A 15.405 15.405 0 0 1 131.641 44.062 A 15.237 15.237 0 0 1 127.225 35.299 A 21.589 21.589 0 0 1 126.969 31.906 Z M 136.219 27.875 L 148.781 27.875 L 148.781 27.156 Q 148.719 24.156 147.156 22.516 A 5.074 5.074 0 0 0 144.69 21.106 Q 143.797 20.875 142.719 20.875 A 6.473 6.473 0 0 0 140.632 21.198 A 5.416 5.416 0 0 0 138.359 22.687 A 7.246 7.246 0 0 0 137.056 24.73 Q 136.468 26.082 136.219 27.875 Z" id="4"/>
      <path stroke="white" stroke-width="1" d="M 181.906 13.969 L 181.781 22.656 Q 179.938 22.406 178.531 22.406 Q 175.546 22.406 173.759 23.583 A 5.36 5.36 0 0 0 171.813 25.875 L 171.813 48 L 162.781 48 L 162.781 14.188 L 171.313 14.188 L 171.563 18.219 A 10.945 10.945 0 0 1 173.519 15.714 A 7.874 7.874 0 0 1 179.094 13.563 Q 180.594 13.563 181.906 13.969 Z" id="5"/>
      <path stroke="white" stroke-width="1" d="M 184.313 31.906 L 184.313 31.031 A 22.613 22.613 0 0 1 184.868 25.923 A 18.558 18.558 0 0 1 186.281 21.922 A 14.952 14.952 0 0 1 189.619 17.402 A 14.085 14.085 0 0 1 191.859 15.734 A 15.258 15.258 0 0 1 198.656 13.617 A 18.735 18.735 0 0 1 200.094 13.563 A 17.705 17.705 0 0 1 204.79 14.152 A 12.834 12.834 0 0 1 211.016 17.937 A 14.283 14.283 0 0 1 214.095 23.519 Q 214.745 25.673 214.928 28.257 A 29.558 29.558 0 0 1 215 30.344 L 215 34.031 L 193.469 34.031 A 9.594 9.594 0 0 0 194.223 36.753 A 7.832 7.832 0 0 0 196.109 39.344 A 7.623 7.623 0 0 0 200.365 41.261 A 10.295 10.295 0 0 0 201.688 41.344 A 11.529 11.529 0 0 0 205.349 40.79 A 9.338 9.338 0 0 0 209.844 37.563 L 214.281 42.531 A 13.306 13.306 0 0 1 209.632 46.59 A 16.078 16.078 0 0 1 208.781 47.016 A 17.473 17.473 0 0 1 202.777 48.559 A 20.997 20.997 0 0 1 201.094 48.625 A 19.765 19.765 0 0 1 195.507 47.872 A 15.405 15.405 0 0 1 188.984 44.062 A 15.237 15.237 0 0 1 184.569 35.299 A 21.589 21.589 0 0 1 184.312 31.906 Z M 193.563 27.875 L 206.125 27.875 L 206.125 27.156 Q 206.063 24.156 204.5 22.516 A 5.074 5.074 0 0 0 202.034 21.106 Q 201.141 20.875 200.063 20.875 A 6.473 6.473 0 0 0 197.976 21.198 A 5.416 5.416 0 0 0 195.703 22.687 A 7.246 7.246 0 0 0 194.4 24.73 Q 193.812 26.082 193.563 27.875 Z" id="6"/>
      <path stroke="white" stroke-width="1" d="M 239.25 13.969 L 239.125 22.656 Q 237.281 22.406 235.875 22.406 Q 232.89 22.406 231.102 23.583 A 5.36 5.36 0 0 0 229.156 25.875 L 229.156 48 L 220.125 48 L 220.125 14.188 L 228.656 14.188 L 228.906 18.219 A 10.945 10.945 0 0 1 230.863 15.714 A 7.874 7.874 0 0 1 236.437 13.563 Q 237.938 13.563 239.25 13.969 Z" id="7"/>
      <path stroke="white" stroke-width="1" d="M 270.469 24.469 L 261.438 24.469 A 4.921 4.921 0 0 0 261.19 22.876 A 4.184 4.184 0 0 0 260.187 21.266 Q 258.938 20 256.281 20 A 8.112 8.112 0 0 0 254.91 20.108 Q 254.17 20.235 253.579 20.512 A 3.869 3.869 0 0 0 252.75 21.031 A 3.464 3.464 0 0 0 251.853 22.122 A 3.313 3.313 0 0 0 251.5 23.656 A 2.655 2.655 0 0 0 252.466 25.736 A 3.998 3.998 0 0 0 252.922 26.078 A 6.496 6.496 0 0 0 253.876 26.577 Q 254.933 27.034 256.552 27.418 A 32.458 32.458 0 0 0 257.719 27.672 A 53.164 53.164 0 0 1 260.101 28.2 Q 261.263 28.488 262.264 28.801 A 26.302 26.302 0 0 1 263.406 29.187 A 14.552 14.552 0 0 1 266.703 30.853 Q 270.563 33.531 270.563 38.281 Q 270.563 42.906 266.594 45.766 A 14.451 14.451 0 0 1 261.561 48.038 Q 259.19 48.625 256.344 48.625 A 20.353 20.353 0 0 1 252.332 48.246 A 15.95 15.95 0 0 1 248.797 47.109 A 13.902 13.902 0 0 1 245.695 45.182 A 11.697 11.697 0 0 1 243.625 42.953 A 10.023 10.023 0 0 1 241.997 39.419 A 9.485 9.485 0 0 1 241.75 37.25 L 250.313 37.25 Q 250.438 39.656 252.094 40.938 A 5.771 5.771 0 0 0 254.136 41.928 Q 255.206 42.219 256.531 42.219 A 10.966 10.966 0 0 0 257.96 42.132 Q 258.685 42.036 259.274 41.836 A 4.279 4.279 0 0 0 260.453 41.234 Q 261.781 40.25 261.781 38.656 A 2.743 2.743 0 0 0 260.745 36.467 A 4.256 4.256 0 0 0 260.141 36.047 A 8.68 8.68 0 0 0 259.061 35.527 Q 257.48 34.883 254.875 34.344 A 30.324 30.324 0 0 1 250.323 33.045 Q 248.093 32.196 246.526 31.062 A 8.239 8.239 0 0 1 242.813 24.094 A 9.123 9.123 0 0 1 245.827 17.206 A 12.385 12.385 0 0 1 246.547 16.578 A 13.085 13.085 0 0 1 251.791 14.06 Q 253.725 13.596 255.989 13.565 A 23.756 23.756 0 0 1 256.312 13.563 A 22.203 22.203 0 0 1 260.563 13.946 Q 264.122 14.64 266.609 16.594 A 10.215 10.215 0 0 1 269.315 19.713 Q 270.469 21.82 270.469 24.469 Z" id="8"/>
      <path stroke="white" stroke-width="1" d="M 281.5 0 L 281.5 4.594 L 280.594 17.219 L 274.875 17.219 L 274.875 0 L 281.5 0 Z" id="9"/>
      
      </g>
    </svg>
    <svg id="svg_main_logo" height={font} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path stroke="white" stroke-width="1" d="M 9.375 2.5 L 9.375 40.469 L 29.281 40.469 L 29.281 48 L 0 48 L 0 2.5 L 9.375 2.5 Z" id="0"/>
      <path stroke="white" stroke-width="1" d="M 62.781 48 L 53.656 48 Q 53.125 46.963 52.842 45.497 A 14.631 14.631 0 0 1 52.75 44.969 A 10.803 10.803 0 0 1 44.867 48.611 A 14.565 14.565 0 0 1 44.219 48.625 Q 39.25 48.625 35.984 45.75 A 9.195 9.195 0 0 1 32.733 39.074 A 11.339 11.339 0 0 1 32.719 38.5 A 11.525 11.525 0 0 1 33.256 34.89 A 9.13 9.13 0 0 1 36.703 30.25 Q 39.648 28.125 44.53 27.554 A 32.964 32.964 0 0 1 48.219 27.344 L 52.375 27.344 L 52.375 25.406 A 7.422 7.422 0 0 0 52.22 23.843 Q 51.995 22.798 51.444 22.008 A 4.485 4.485 0 0 0 51.172 21.656 A 3.717 3.717 0 0 0 49.501 20.559 Q 48.882 20.349 48.123 20.282 A 8.482 8.482 0 0 0 47.375 20.25 A 7.686 7.686 0 0 0 45.953 20.373 Q 44.658 20.617 43.797 21.344 A 3.577 3.577 0 0 0 42.532 23.768 A 5.088 5.088 0 0 0 42.5 24.344 L 33.469 24.344 A 9.067 9.067 0 0 1 35.267 18.926 A 11.034 11.034 0 0 1 35.281 18.906 A 11.097 11.097 0 0 1 38.438 16.006 A 14.557 14.557 0 0 1 40.406 14.984 A 17.514 17.514 0 0 1 45.345 13.701 A 21.906 21.906 0 0 1 47.844 13.562 A 20.147 20.147 0 0 1 51.92 13.951 Q 54.41 14.465 56.341 15.662 A 11.558 11.558 0 0 1 57.766 16.703 A 10.246 10.246 0 0 1 61.229 22.965 A 15.113 15.113 0 0 1 61.438 25.531 L 61.438 40.188 Q 61.466 44.563 62.553 47.001 A 7.535 7.535 0 0 0 62.781 47.469 L 62.781 48 Z M 52.375 38.438 L 52.375 32.625 L 49 32.625 A 14.665 14.665 0 0 0 46.507 32.818 Q 42.13 33.577 41.781 37.313 L 41.75 37.844 A 3.918 3.918 0 0 0 42.011 39.295 A 3.587 3.587 0 0 0 42.938 40.625 Q 44.065 41.663 45.98 41.716 A 7.532 7.532 0 0 0 46.188 41.719 Q 48.188 41.719 49.875 40.828 A 6.474 6.474 0 0 0 51.373 39.757 A 5.436 5.436 0 0 0 52.375 38.438 Z" id="1"/>
      <path stroke="white" stroke-width="1" d="M 98.844 30.906 L 98.844 31.406 Q 98.844 37.143 97.115 41.096 A 14.421 14.421 0 0 1 95.375 44.078 A 11.194 11.194 0 0 1 87.199 48.554 A 15.697 15.697 0 0 1 85.687 48.625 Q 80.188 48.625 76.906 44.406 L 76.5 48 L 68.375 48 L 68.375 0 L 77.406 0 L 77.406 17.219 A 10.15 10.15 0 0 1 84.84 13.585 A 13.577 13.577 0 0 1 85.625 13.562 A 13.487 13.487 0 0 1 90.051 14.256 A 11.195 11.195 0 0 1 95.328 18.109 A 15.252 15.252 0 0 1 97.792 22.99 Q 98.844 26.395 98.844 30.906 Z M 77.406 24.438 L 77.406 37.781 Q 78.774 40.724 81.976 41.236 A 9.058 9.058 0 0 0 83.406 41.344 A 6.694 6.694 0 0 0 85.787 40.946 Q 87.634 40.248 88.628 38.324 A 8.314 8.314 0 0 0 89.156 37.031 A 10.37 10.37 0 0 0 89.473 35.701 Q 89.733 34.232 89.794 32.11 A 47.525 47.525 0 0 0 89.813 30.75 A 25.663 25.663 0 0 0 89.688 28.12 Q 89.412 25.456 88.529 23.825 A 6.136 6.136 0 0 0 88.187 23.266 A 5.275 5.275 0 0 0 84.312 20.964 A 7.847 7.847 0 0 0 83.344 20.906 A 8.114 8.114 0 0 0 81.02 21.217 A 5.627 5.627 0 0 0 77.406 24.438 Z" id="2"/>
      <path stroke="white" stroke-width="1" d="M 103 31.188 L 103 30.781 A 22.318 22.318 0 0 1 103.543 25.762 A 18.124 18.124 0 0 1 104.937 21.812 Q 106.875 17.875 110.516 15.719 A 15.413 15.413 0 0 1 116.495 13.715 A 19.59 19.59 0 0 1 118.969 13.562 A 18.281 18.281 0 0 1 124.114 14.254 A 14.311 14.311 0 0 1 130.141 17.75 A 15.36 15.36 0 0 1 134.282 24.926 A 21.786 21.786 0 0 1 134.969 29.125 L 135.031 31.438 A 22.609 22.609 0 0 1 134.421 36.837 A 15.795 15.795 0 0 1 130.687 43.922 A 14.376 14.376 0 0 1 121.774 48.446 A 20.286 20.286 0 0 1 119.031 48.625 A 18.438 18.438 0 0 1 113.817 47.924 A 14.081 14.081 0 0 1 107.359 43.938 Q 103.104 39.362 103.002 31.571 A 29.378 29.378 0 0 1 103 31.188 Z M 112.031 31.437 A 20.497 20.497 0 0 0 112.204 34.192 Q 112.391 35.57 112.782 36.693 A 8.313 8.313 0 0 0 113.844 38.797 Q 115.656 41.344 119.031 41.344 A 6.855 6.855 0 0 0 121.375 40.96 A 5.889 5.889 0 0 0 124.156 38.828 Q 126 36.313 126 30.781 A 19.531 19.531 0 0 0 125.82 28.04 Q 125.616 26.602 125.18 25.432 A 8.474 8.474 0 0 0 124.156 23.469 A 5.869 5.869 0 0 0 119.892 20.928 A 7.864 7.864 0 0 0 118.969 20.875 A 6.777 6.777 0 0 0 116.631 21.261 A 5.797 5.797 0 0 0 113.844 23.453 Q 112.031 26.031 112.031 31.438 Z" id="3"/>
      <path stroke="white" stroke-width="1" d="M 159.719 13.969 L 159.594 22.656 Q 157.75 22.406 156.344 22.406 Q 153.358 22.406 151.571 23.583 A 5.36 5.36 0 0 0 149.625 25.875 L 149.625 48 L 140.594 48 L 140.594 14.188 L 149.125 14.188 L 149.375 18.219 A 10.945 10.945 0 0 1 151.332 15.714 A 7.874 7.874 0 0 1 156.906 13.563 Q 158.406 13.563 159.719 13.969 Z" id="4"/>
      <path stroke="white" stroke-width="1" d="M 191.719 48 L 182.594 48 Q 182.062 46.963 181.779 45.497 A 14.631 14.631 0 0 1 181.688 44.969 A 10.803 10.803 0 0 1 173.805 48.611 A 14.565 14.565 0 0 1 173.156 48.625 Q 168.188 48.625 164.922 45.75 A 9.195 9.195 0 0 1 161.671 39.074 A 11.339 11.339 0 0 1 161.656 38.5 A 11.525 11.525 0 0 1 162.193 34.89 A 9.13 9.13 0 0 1 165.641 30.25 Q 168.585 28.125 173.468 27.554 A 32.964 32.964 0 0 1 177.156 27.344 L 181.313 27.344 L 181.313 25.406 A 7.422 7.422 0 0 0 181.157 23.843 Q 180.932 22.798 180.381 22.008 A 4.485 4.485 0 0 0 180.109 21.656 A 3.717 3.717 0 0 0 178.439 20.559 Q 177.82 20.349 177.06 20.282 A 8.482 8.482 0 0 0 176.312 20.25 A 7.686 7.686 0 0 0 174.891 20.373 Q 173.596 20.617 172.734 21.344 A 3.577 3.577 0 0 0 171.469 23.768 A 5.088 5.088 0 0 0 171.438 24.344 L 162.406 24.344 A 9.067 9.067 0 0 1 164.205 18.926 A 11.034 11.034 0 0 1 164.219 18.906 A 11.097 11.097 0 0 1 167.376 16.006 A 14.557 14.557 0 0 1 169.344 14.984 A 17.514 17.514 0 0 1 174.282 13.701 A 21.906 21.906 0 0 1 176.781 13.562 A 20.147 20.147 0 0 1 180.857 13.951 Q 183.348 14.465 185.278 15.662 A 11.558 11.558 0 0 1 186.703 16.703 A 10.246 10.246 0 0 1 190.167 22.965 A 15.113 15.113 0 0 1 190.375 25.531 L 190.375 40.188 Q 190.403 44.563 191.491 47.001 A 7.535 7.535 0 0 0 191.719 47.469 L 191.719 48 Z M 181.313 38.438 L 181.313 32.625 L 177.938 32.625 A 14.665 14.665 0 0 0 175.444 32.818 Q 171.067 33.577 170.719 37.313 L 170.688 37.844 A 3.918 3.918 0 0 0 170.948 39.295 A 3.587 3.587 0 0 0 171.875 40.625 Q 173.002 41.663 174.918 41.716 A 7.532 7.532 0 0 0 175.125 41.719 Q 177.125 41.719 178.813 40.828 A 6.474 6.474 0 0 0 180.31 39.757 A 5.436 5.436 0 0 0 181.313 38.438 Z" id="5"/>
      <path stroke="white" stroke-width="1" d="M 199.094 5.875 L 208.125 5.875 L 208.125 14.188 L 213.906 14.188 L 213.906 20.813 L 208.125 20.813 L 208.125 37.688 Q 208.125 39.563 208.844 40.375 A 1.93 1.93 0 0 0 209.528 40.853 Q 210.29 41.188 211.594 41.188 A 17.654 17.654 0 0 0 212.84 41.146 Q 213.457 41.102 214.002 41.012 A 10.337 10.337 0 0 0 214.25 40.969 L 214.25 47.813 Q 211.594 48.625 208.781 48.625 A 13.577 13.577 0 0 1 204.955 48.133 Q 200.679 46.875 199.52 42.406 A 14.608 14.608 0 0 1 199.094 39.031 L 199.094 20.813 L 194.156 20.813 L 194.156 14.188 L 199.094 14.188 L 199.094 5.875 Z" id="6"/>
      <path stroke="white" stroke-width="1" d="M 216.594 31.188 L 216.594 30.781 A 22.318 22.318 0 0 1 217.137 25.762 A 18.124 18.124 0 0 1 218.531 21.812 Q 220.469 17.875 224.109 15.719 A 15.413 15.413 0 0 1 230.089 13.715 A 19.59 19.59 0 0 1 232.563 13.562 A 18.281 18.281 0 0 1 237.708 14.254 A 14.311 14.311 0 0 1 243.734 17.75 A 15.36 15.36 0 0 1 247.876 24.926 A 21.786 21.786 0 0 1 248.562 29.125 L 248.625 31.438 A 22.609 22.609 0 0 1 248.015 36.837 A 15.795 15.795 0 0 1 244.281 43.922 A 14.376 14.376 0 0 1 235.368 48.446 A 20.286 20.286 0 0 1 232.625 48.625 A 18.438 18.438 0 0 1 227.411 47.924 A 14.081 14.081 0 0 1 220.953 43.938 Q 216.698 39.362 216.596 31.571 A 29.378 29.378 0 0 1 216.594 31.188 Z M 225.625 31.437 A 20.497 20.497 0 0 0 225.798 34.192 Q 225.985 35.57 226.376 36.693 A 8.313 8.313 0 0 0 227.437 38.797 Q 229.25 41.344 232.625 41.344 A 6.855 6.855 0 0 0 234.969 40.96 A 5.889 5.889 0 0 0 237.75 38.828 Q 239.594 36.313 239.594 30.781 A 19.531 19.531 0 0 0 239.414 28.04 Q 239.209 26.602 238.774 25.432 A 8.474 8.474 0 0 0 237.75 23.469 A 5.869 5.869 0 0 0 233.485 20.928 A 7.864 7.864 0 0 0 232.563 20.875 A 6.777 6.777 0 0 0 230.225 21.261 A 5.797 5.797 0 0 0 227.437 23.453 Q 225.625 26.031 225.625 31.438 Z" id="7"/>
      <path stroke="white" stroke-width="1" d="M 273.313 13.969 L 273.188 22.656 Q 271.344 22.406 269.938 22.406 Q 266.952 22.406 265.165 23.583 A 5.36 5.36 0 0 0 263.219 25.875 L 263.219 48 L 254.188 48 L 254.188 14.188 L 262.719 14.188 L 262.969 18.219 A 10.945 10.945 0 0 1 264.925 15.714 A 7.874 7.874 0 0 1 270.5 13.563 Q 272 13.563 273.313 13.969 Z" id="8"/>
      <path stroke="white" stroke-width="1" d="M 284.438 14.188 L 290.719 35.219 L 296.969 14.188 L 306.656 14.188 L 293.063 53.25 L 292.313 55.031 A 12.906 12.906 0 0 1 290 58.56 Q 287.35 61.366 283.19 61.629 A 13.901 13.901 0 0 1 282.312 61.656 A 14.007 14.007 0 0 1 278.819 61.202 A 15.9 15.9 0 0 1 278.312 61.062 L 278.313 54.219 L 279.688 54.25 A 13.503 13.503 0 0 0 281.026 54.188 Q 282.606 54.03 283.516 53.469 Q 284.781 52.688 285.5 50.875 L 286.563 48.094 L 274.719 14.188 L 284.438 14.188 Z" id="9"/>

    </g>
  </svg>
  </>
  )
}

export default TinkerersLabText