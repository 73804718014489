import { Button, Checkbox, Grid, makeStyles, TextField, Select, MenuItem } from '@material-ui/core'
import React, { useState } from 'react'
import { emailValidator } from '../utils/validators';
import AddAdornment from './AddAdornment';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { URLs } from '../utils/constants'

// PENDING -
// Remove field
// Back Btn

const emptyDataObject = {
  name: "",
  members: [""]
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
})
)

function TeamCreate() {
  const classes = useStyles()
  const [data, setData] = useState(emptyDataObject)
  const [error, setError] = useState(emptyDataObject)
  const [users, setUsers] = useState([]) // [ {name: "name", email: "email"}, ...
  const navigate = useNavigate()
  const handleChange = (e) => {
    getUsers()
    setData({
      ...data, [e.target.name]: e.target.value,
    })
  }
  const handleMember = (e) => {
    const newMembers = [...data.members]
    const idx = e.target.name.split('-')[1]
    newMembers[idx] = e.target.value
    setData({
      ...data, members: newMembers
    })
  }
  const addMember = () => {
    getUsers()
    setData({
      ...data,
      members: [...data.members, ""]
    })
    setError({
      ...error,
      members: [...error.members, ""]
    })
  }
 const handleSave = (e) => {
    e.preventDefault()
    const nameErr = data.name.length === 0 ? "Team name cannot be empty!" : ""
    const memberErrs = data.members.map(email => emailValidator(email) ? "" : "Please enter a valid email id")
    if (nameErr.length || memberErrs.filter(item => item.length > 0).length) {
      setError({ name: nameErr, members: memberErrs })
      return;
    }
    setError({
      ...emptyDataObject,
      members: error.members.map(_ => "")
    })
    const reqData = {
      name: data.name,
      emails: data.members
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token1')}`,
        'Content-Type': 'application/json'
      }
    }
console.log(reqData)
    axios.post("/auth/team/", reqData, config)
      .then(res => {
        navigate(URLs.home.route)
      })
      .catch(err => {
        setError({
          ...error,
          members: data.members.map(email => err.response.data.users.includes(email) ? "User not found!" : "")
        })
      })
  }




   const getUsers = () => {
     axios.get('/auth/user-all/')
       .then(res => {
        console.log(res.data)
          setUsers(res.data)
       })
       .catch(err => {
         console.log(err)
       })
   }

  const removeMember = (idx) => {
    if (data.members.length === 1) {
      return;
    }
    const newMembers = [...data.members];
    newMembers.splice(idx, 1);
    setData({
      ...data,
      members: newMembers
    });
    setError({
      ...error,
      members: error.members.filter((_, i) => i !== idx)
    });
  };
  
//  return (
  //  <Grid container justifyContent='center' component='form' onSubmit={handleSave}>
    //  <Grid item xs={12} sm={8} className={classes.margin}>
    //    <TextField onChange={handleChange} value={data.name} error={error.name} helperText={error.name} name="name" placeholder="Enter the team name" label="Team Name" variant="outlined" fullWidth />
     // </Grid>
     //{/* <Grid item xs={12}>
       // <Typography variant="h5" component="div" align="center"> Add team members </Typography>
      //</Grid> */}
      //{
        
        //  <Grid item xs={12} sm={8} className={classes.margin}>
          //  {/* use selectfield to display selection of all users */}
           // <Select
            //  onChange={handleMember}
           //   placeholder="Enter member's email"
            //  label="Member Email"
             // variant="outlined"
             // fullWidth
              
       //     />
         //   {users.map((user, idx) => (
        //      <>
        //      <MenuItem key={idx} value={user.email} >{user.fname}</MenuItem>
          //    <Button type="button" onClick={addMember}>Add</Button>
            //  <Button type="button" onClick={() => removeMember(idx)}>Delete</Button>
             // </>
           // ))}
            
        //  </Grid>
     // }
    //  <Grid container justifyContent="center">
      //  <Grid item xs={12} sm={4} lg={3}>
        //  <Button fullWidth type="submit" variant="contained" color="primary">Save</Button>
      //  </Grid>
     // </Grid>
   // </Grid>
    
 // )
//}
return (
  <Grid container justifyContent='center' component='form' onSubmit={handleSave}>
    <Grid item xs={12} sm={8} className={classes.margin}>
      <TextField onChange={handleChange} value={data.name} error={error.name} helperText={error.name} name="name" placeholder="Enter the team name" label="Team Name" variant="outlined" fullWidth />
    </Grid>
   {/* <Grid item xs={12}>
      <Typography variant="h5" component="div" align="center"> Add team members </Typography>
    </Grid> */}
    {
      data.members.map((member, idx) => (
        <div key={idx}>
            <TextField
              onChange={handleMember}
              value={member.firstName}
              error={error.members[idx]?.firstName}
              helperText={error.members[idx]?.firstName}
              name={`member-${idx}-firstName`}
              placeholder="Enter member's first name"
              label="First Name"
              variant="outlined"
              fullWidth
            />
            <TextField
              onChange={handleMember}
              value={member.lastName}
              error={error.members[idx]?.lastName}
              helperText={error.members[idx]?.lastName}
              name={`member-${idx}-lastName`}
              placeholder="Enter member's last name"
              label="Last Name"
              variant="outlined"
              fullWidth
            />
            <TextField
              onChange={handleMember}
              value={member.email}
              error={error.members[idx]?.email}
              helperText={error.members[idx]?.email}
              name={`member-${idx}-email`}
              placeholder="Enter member's email"
              label="Email"
              variant="outlined"
              fullWidth
            />
            <Button onClick={() => removeMember(idx)}>Delete</Button>
          <Button type="button" onClick={addMember}>Add</Button>
        </div>
      ))
    
      
    }
    
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={4} lg={3}>
        <Button fullWidth type="submit" variant="contained" color="primary">Save</Button>
      </Grid>
    </Grid>
  </Grid>
  
)
}

export default TeamCreate
